export default function Edit() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width={22} height={21} fill="none">
        <path
            fill="#fff"
            d="M1.562 20.656a.781.781 0 0 1-.78-.867l.6-5.469a.781.781 0 0 1 .227-.46L14.39 1.077a2.5 2.5 0 0 1 3.54 0l2.43 2.43a2.5 2.5 0 0 1 0 3.539L7.585 19.82a.782.782 0 0 1-.461.227l-5.469.601-.094.008Zm1.352-5.898-.469 4.234 4.235-.469L19.258 5.945a.937.937 0 0 0 0-1.328L16.82 2.18a.937.937 0 0 0-1.328 0L2.914 14.758Z"
        />
        <path
            fill="#fff"
            d="M17.968 9.102a.781.781 0 0 1-.554-.227l-4.852-4.867a.785.785 0 1 1 1.11-1.11l4.867 4.868a.779.779 0 0 1 0 1.109.78.78 0 0 1-.57.227ZM12.567 7.768l-6.083 6.083 1.105 1.105 6.082-6.083-1.104-1.105Z"
        />
    </svg>)
}
