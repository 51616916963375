export default function Delete() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width={21} height={25} fill="none">
        <path
            fill="#fff"
            d="M16.75 24.219H4.25a2.344 2.344 0 0 1-2.344-2.344V7.031a.781.781 0 0 1 1.563 0v14.844a.781.781 0 0 0 .781.781h12.5a.781.781 0 0 0 .781-.781V7.031a.781.781 0 0 1 1.563 0v14.844a2.344 2.344 0 0 1-2.344 2.344ZM19.875 5.469H1.125a.781.781 0 0 1 0-1.563h18.75a.781.781 0 1 1 0 1.563Z"
        />
        <path
            fill="#fff"
            d="M13.625 5.469a.781.781 0 0 1-.781-.782V2.345H8.156v2.344a.781.781 0 0 1-1.562 0V1.563A.781.781 0 0 1 7.375.78h6.25a.781.781 0 0 1 .781.781v3.125a.781.781 0 0 1-.781.782ZM10.5 20.313a.781.781 0 0 1-.781-.782V8.594a.781.781 0 0 1 1.562 0V19.53a.781.781 0 0 1-.781.782ZM14.406 18.75a.781.781 0 0 1-.781-.781v-7.813a.781.781 0 0 1 1.563 0v7.813a.781.781 0 0 1-.782.781ZM6.594 18.75a.781.781 0 0 1-.782-.781v-7.813a.781.781 0 0 1 1.563 0v7.813a.781.781 0 0 1-.781.781Z"
        />
    </svg>)
}
